import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	padding-top: 1rem;

	> * {
		border: 1px solid #ffffff;
		border-radius: 0.5rem;
		padding: 0.5rem;
		width: 8rem;
		margin-bottom: 1rem;

		img {
			width: 100%;
		}

		margin-right: 1rem;
	}
`;

export const ImgContainer = styled.div`
	border: 1px solid #ffffff;
	border-radius: 0.5rem;
	padding: 0.5rem;
	width: 8rem;
	margin-bottom: 1rem;

	img {
		width: 100%;
	}

	:not(:last-child) {
		margin-right: 1rem;
	}
`;
