import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import * as S from "./Gallery.styles";

const Gallery = () => {
	const data = useStaticQuery<{
		allFile: {
			nodes: {
				childImageSharp: {
					gatsbyImageData: {
						backgroundColor: string;
						images: {
							sources: { srcSet: string }[];
						};
					};
				};
			}[];
		};
	}>(graphql`
		{
			allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
				nodes {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	`);

	return (
		<S.Container>
			{data.allFile.nodes.map((images) => (
				<img
					srcSet={
						images.childImageSharp.gatsbyImageData.images.sources[0].srcSet
					}
				/>
			))}
		</S.Container>
	);
};

export default Gallery;
